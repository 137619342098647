import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
  Link
} from '@chakra-ui/react';

export default function FooterComponent() {
  return (
    <Box
      bg={useColorModeValue('gray.50', '#121212')}
      color={useColorModeValue('gray.700', 'gray.200')}
      mt="auto"
      >
      <Container
        as={Stack}
        maxW='1250px'
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}>
        <Text>© {new Date().getFullYear()} <Link href="https://vaccinator.tech" isExternal>Vaccinator Security</Link>. Dangercord is not affiliated with or endorsed by Discord.</Text>
      </Container>
    </Box>
  );
}